import React from "react";
import styles from "./ForgotPassword.module.css";
function PageNotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <img className={styles.logo} src="./logo.png" />

      <h1 style={{color: "black;"}}>Page Not Found</h1>
    </div>
  );
}

export default PageNotFound;
